<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ orderInfo.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="换货机构">
              {{ orderInfo.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ orderInfo.purchaserCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ orderInfo.producerCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ orderInfo.createTime ? tsFormat(orderInfo.createTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态">
              <span>
                {{
                  fromRouteName === "ChangeOrderListFactory"
                    ? orderInfo.nodeStatus
                    : orderInfo.orderStatusValue
                }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(orderInfo)">
                  {{ orderInfo.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <section class="card__container">
      <div class="container__title">客户发货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="发货人姓名">
              {{ orderInfo.purName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="发货人电话">
              {{ orderInfo.purTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="发货地址">
              {{ orderInfo.purAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="goodsSn" slot-scope="text, record">
          <span v-if="record.skuCode === '合计：'"></span>
          <span v-else>{{ text ? text : "-" }}</span>
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">合同信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="合同名称">
              <a
                :href="imageBaseUrl + contractInfo.contractFile.fileUrl"
                target="_blank"
                v-if="
                  contractInfo &&
                    contractInfo.contractFile &&
                    contractInfo.contractFile.fileUrl
                "
              >
                {{ contractInfo.contractFile.fileName }}
              </a>
              <span v-else-if="contractInfo && contractInfo.contractName">
                {{ contractInfo.contractName }}
              </span>
              <span v-else>
                -
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="签订时间">
              {{
                contractInfo && contractInfo.signTime
                  ? tsFormat(contractInfo.signTime)
                  : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">
        物流信息
        <span v-if="deliverList.length > 0">
          （{{ deliverList.length }}）条
        </span>
        <a-button
          @click="openReceiveModal"
          type="primary"
          class="btn--add"
          v-if="
            (orderInfo.orderStatusValue === '未收货' ||
              orderInfo.orderStatusValue === '部分收货') &&
              type === 'receive'
          "
        >
          添加收货清单
        </a-button>
      </div>
      <a-collapse default-active-key="0" style="margin-top: 20px">
        <a-collapse-panel
          v-for="(item, index) in deliverList"
          :key="index"
          :header="`第${changeNumToString(index + 1)}批次明细`"
        >
          <div slot="extra">
            发货时间：{{ tsFormat(item.logisticsSendTime) }}
          </div>
          <a-form class="advanced-search-form">
            <a-row>
              <a-col :span="8">
                <a-form-item label="物流电话">
                  {{ item.logisticsTel || "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="物流公司">
                  {{ item.logisticsName || "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="物流单号">
                  {{ item.logisticsSn || "-" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="发货清单">
                  <a-table
                    :columns="inventoryColumns"
                    :data-source="calculateDetail(item.detailVoList)"
                    :loading="loading"
                    :pagination="false"
                    rowKey="skuId"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <div v-if="item.receiveBill && item.receiveBill.length > 0">
              <div v-for="ele in item.receiveBill" :key="ele.receivingId">
                <a-row>
                  <a-col :span="8">
                    <a-form-item label="收货时间">
                      {{ ele.signTime ? tsFormat(ele.signTime) : "-" }}
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="发票信息">
                      <a>查看</a>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="收货清单">
                      <a-table
                        :columns="inventoryColumns"
                        :data-source="calculateDetail(ele.deliverVoList)"
                        :loading="loading"
                        :pagination="false"
                        rowKey="skuId"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-form>
        </a-collapse-panel>
      </a-collapse>
    </section>
    <section
      class="card__container "
      :class="
        type === 'deliver' &&
        (orderInfo.orderStatusValue === '待发货' ||
          orderInfo.orderStatusValue === '部分发货')
          ? 'high__bottom'
          : 'low__bottom'
      "
    >
      <div class="container__title ">流程日志</div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="reason" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </section>
    <div
      class="operation__container"
      v-if="
        type === 'deliver' &&
          (orderInfo.orderStatusValue === '待发货' ||
            orderInfo.orderStatusValue === '部分发货')
      "
    >
      <a-button type="primary" @click="onDeliver">发货</a-button>
    </div>

    <a-modal
      title="添加收货清单"
      :visible="receiveModal"
      width="60%"
      @cancel="onCloseReceiveModal"
      @ok="onSubmitReceive"
    >
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="6">
            <a-form-item label="发货批次" class="form__item">
              <a-select
                v-model="batch"
                @change="onSelectBatch"
                style="width: 100px"
              >
                <a-select-option
                  v-for="item in batchList"
                  :key="item.consignmentId"
                >
                  {{ item.index }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="18" class="flex__end">
            <a-button icon="plus" type="primary" @click="onAdd">添加</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        class="receive__table"
        :columns="receiveColumns"
        :data-source="receiveData"
        :pagination="false"
        rowKey="index"
      >
        <template
          v-for="column in ['skuName']"
          :slot="column"
          slot-scope="text, record, index"
        >
          <div :key="column">
            <a-select
              style="width: 200px"
              @change="onSelectSku($event, index)"
              :value="record.skuName"
            >
              <a-select-option
                v-for="item in unReceiveList"
                :key="item.index"
                :disabled="item.disabled"
              >
                {{ item.skuName }}
              </a-select-option>
            </a-select>
          </div>
        </template>
        <template
          v-for="col in ['quantity']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <div v-if="record.goodsSn">{{ record.quantity }}</div>
            <a-input-number
              v-else
              :min="1"
              :max="record.quantity || 1"
              :formatter="formatInteger"
              :parser="formatInteger"
              v-model="record.quantity"
            />
          </div>
        </template>
        <template slot="goodsSn" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
        <template slot="action" slot-scope="text, record, index">
          <span class="red" @click="onDelete(index)">删除</span>
        </template>
      </a-table>
    </a-modal>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import {
  checkUnReceiveSkuByInvoice,
  fetchOrderDetail,
  fetchOrderLog,
  receiverSku
} from "@/services/OrderManageService";
import Breadcrumb from "@/components/Breadcrumb";
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";
import { changeNumToString } from "@/components/utils";

const commodityColumns = [
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" },
    width: "15%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "换货数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "15%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];

const receiveColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    scopedSlots: { customRender: "skuName" },
    width: "30%"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" },
    width: "15%"
  },
  {
    title: "数量",
    dataIndex: "quantity",
    scopedSlots: { customRender: "quantity" },
    width: "15%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  name: "ToReceiverOrderDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      loading: false,
      orderInfo: {},
      tsFormat,
      commodityColumns,
      commodityData: [],
      imageBaseUrl,
      logColumns,
      logData: [],
      logLoading: false,
      deliverList: [],
      changeNumToString,
      inventoryColumns,
      receiveModal: false,
      batchList: [],
      receiveColumns,
      receiveData: [],
      batch: "",
      unReceiveList: [],
      type: this.$route.params.type,
      fromRouteName: "",
      contractInfo: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouteName = from.name;
    });
  },
  mounted() {
    this.loadDetail();
    this.loadLogList();
    this.loadDeliverInfo();
  },
  methods: {
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: this.type === "receive" ? "receive" : "deliver"
      };
      fetchOrderDetail(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.orderInfo = resp.data.data;
            if (
              this.orderInfo.instantOrderSkus &&
              this.orderInfo.instantOrderSkus.length > 0
            ) {
              this.commodityData = this.calculate(
                this.orderInfo.instantOrderSkus
              );
            }
            if (this.orderInfo.contracts && this.orderInfo.contracts.length) {
              let index = this.orderInfo.contracts.findIndex(
                ele => ele.contractType === "卖方订单合同签订"
              );
              if (index >= 0) {
                this.contractInfo = this.orderInfo.contracts[index];
              }
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 计算
    calculate(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let num = 0;
        let allPrice = 0;
        list.forEach(item => {
          num = num + parseInt(item.purchaseQuantity);
          allPrice = allPrice + parseFloat(item.skuFactoryTotalPrice);
        });
        const params = {
          skuCode: "合计：",
          purchaseQuantity: num,
          skuFactoryTotalPrice: allPrice.toFixed(2)
        };
        arr.push(params);
      }
      return arr;
    },
    // 计算清单合计
    calculateDetail(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let num = 0;
        let allPrice = 0;
        list.forEach(item => {
          num = num + parseInt(item.quantity);
          allPrice = allPrice + parseFloat(item.skuFactoryPrice);
        });
        const params = {
          skuName: "合计：",
          quantity: num,
          skuFactoryPrice: allPrice.toFixed(2)
        };
        arr.push(params);
      }
      return arr;
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "producer").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 加载发货单
    loadDeliverInfo() {
      this.loading = true;
      fetchSaleBillByOrderSn(this.orderSn).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.deliverList = resp.data.data;
          if (this.deliverList && this.deliverList.length > 0) {
            this.batchList = [];
            this.deliverList.forEach((item, index) => {
              this.batchList.push({
                index: index + 1,
                consignmentId: item.consignmentId
              });
              this.loadReceiveBill(item.consignmentId);
            });
            this.batch = this.deliverList[0].consignmentId;
            this.loadUnReceiveSku();
          }
        }
      });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          let index = this.deliverList.findIndex(
            ele => ele.consignmentId === consignmentId
          );
          this.deliverList[index].receiveBill = data;
          this.deliverList = [...this.deliverList];
        }
      });
    },
    // 打开收货清单弹窗
    openReceiveModal() {
      this.receiveModal = true;
      this.loadDeliverInfo();
    },
    // 根据发货单查询未收货商品
    loadUnReceiveSku() {
      checkUnReceiveSkuByInvoice(this.batch).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.unReceiveList = resp.data.data;
          if (this.unReceiveList && this.unReceiveList.length > 0) {
            this.unReceiveList.forEach((item, index) => {
              item.index = index;
              item.disabled = false;
            });
          }
        }
      });
    },
    // 选择批次
    onSelectBatch() {
      this.loadUnReceiveSku();
    },
    // 添加
    onAdd() {
      const params = {
        skuName: "",
        skuCode: "",
        goodsSn: "",
        quantity: "",
        skuFactoryPrice: "",
        index: "",
        skuId: ""
      };
      this.receiveData.push(params);
    },
    // 删除
    onDelete(index) {
      this.receiveData.splice(index, 1);
      if (this.unReceiveList && this.unReceiveList.length > 0) {
        this.unReceiveList.forEach(ele => {
          ele.disabled = false;
        });
      }
      let list = this.receiveData.filter(ele => ele.skuName);
      if (list.length > 0) {
        list.forEach(ele => {
          this.unReceiveList[ele.index].disabled = true;
        });
      }
    },
    // 关闭收货清单
    onCloseReceiveModal() {
      this.receiveModal = false;
    },
    // 选择sku
    onSelectSku(value, index) {
      let obj = this.unReceiveList[value];
      this.receiveData[index] = {
        skuName: obj.skuName,
        skuCode: obj.skuCode,
        goodsSn: obj.goodsSn,
        quantity: obj.quantity,
        skuId: obj.skuId,
        skuFactoryPrice: obj.skuFactoryPrice,
        index: obj.index
      };
      this.receiveData = [...this.receiveData];
      if (this.unReceiveList && this.unReceiveList.length > 0) {
        this.unReceiveList.forEach(ele => {
          ele.disabled = false;
        });
      }
      let list = this.receiveData.filter(ele => ele.skuName);
      if (list.length > 0) {
        list.forEach(ele => {
          this.unReceiveList[ele.index].disabled = true;
        });
      }
    },
    // 限制小数
    formatInteger(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 1;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 1;
      }
      return 1;
    },
    // 提交收货清单
    onSubmitReceive() {
      if (this.receiveData.length === 0) {
        this.$message.info("请选择收货的商品");
        return;
      }
      let index = this.receiveData.findIndex(item => !item.skuName);
      if (index > -1) {
        this.$message.info("请填写完整");
        return;
      }
      let detailFormList = [];
      this.receiveData.forEach(ele => {
        detailFormList.push({
          skuId: ele.skuId,
          goodsSn: ele.goodsSn || "",
          quantity: ele.quantity
        });
      });
      const params = {
        orderSn: this.orderSn,
        consignmentId: this.batch,
        detailFormList: detailFormList
      };
      receiverSku(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("收货成功");
          this.receiveModal = false;
          this.receiveData = [];
          this.loadDetail();
          this.loadDeliverInfo();
        }
      });
    },
    // 发货
    onDeliver() {
      this.$router.push({
        name: "PublicDeliver",
        params: {
          orderSn: this.orderInfo.orderSn,
          type: this.orderInfo.orderType
        }
      });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderInfo) {
      let orderSn = orderInfo.orderPurchaseSn;
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailFactory",
          params: { orderSn: orderSn, type: "detail" }
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.commodity__table,
.log__table {
  margin-top: 20px;
}

.btn--add {
  margin-left: 20px;
}

.flex__end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form__item {
  margin: 0 40px 0 0;
  display: flex;
}

.receive__table {
  margin-top: 20px;
}

.red {
  color: #bd3124;
  cursor: pointer;
}

.high__bottom {
  margin-bottom: 80px;
}

.low__bottom {
  margin-bottom: 30px;
}

.operation__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
</style>
